import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

function Amount({ amount }) {
  return (
    <Typography variant="button" style={{ width: '80px', display: 'block' }}>
      <b>
        {amount.amount} <small>{amount.currency}</small>
      </b>
    </Typography>
  );
}

Amount.propTypes = {
  amount: PropTypes.object.isRequired,
};

export default Amount;
