import React, { useCallback, useState, useEffect } from 'react';
import get from 'lodash/get';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import useStyles from '../../../hooks/useStyles';
import { useField, useForm } from 'react-final-form-hooks';
import { changeProfileAction, authenticateAction } from '../../../api/auth';
import { Alert } from '@material-ui/lab';
import { countryList } from '../../../config';
import { withRouter } from 'react-router-dom';
import amplitude from '../../../util/amplitude';

export default withRouter(function ChangeProfile({ history }) {
  const [error, setError] = useState('');
  const [initialValues, setInitialValues] = useState({
    firstName: '',
    lastName: '',
    website: '',
    companyName: '',
    address: {
      country: '',
    },
  });
  const classes = useStyles();

  const onSubmit = useCallback(
    async (...values) => {
      const response = await changeProfileAction(...values);

      if (response.errors) {
        setError(get(response, 'errors[0]', 'Invalid Fields'));
      } else {
        history.push('/dashboard');
      }
    },
    [history]
  );

  const onCancel = useCallback(() => {
    history.push('/dashboard');
  }, [history]);

  useEffect(() => {
    authenticateAction().then(response =>
      setInitialValues(get(response, 'customer.profile'))
    );

    amplitude.getInstance().logEvent('Open Change Profile Form');
  }, []);

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues,
  });

  const firstNameField = useField('firstName', form);
  const lastNameField = useField('lastName', form);
  const companyField = useField('companyName', form);
  const countryField = useField('address.country', form);
  const websiteField = useField('website', form);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {error ? <Alert severity="error">{error}</Alert> : ''}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            {...firstNameField.input}
            variant="outlined"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            {...lastNameField.input}
            autoComplete="lname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="change-country"
            select
            label="Country"
            SelectProps={{
              native: true,
            }}
            {...countryField.input}
            fullWidth
            variant="outlined"
          >
            {countryList.map(({ name, code }) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="company"
            label="Company"
            {...companyField.input}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            id="website"
            label="Website"
            {...websiteField.input}
          />
        </Grid>
      </Grid>
      <div className={classes.margin}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Change Profile
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
});
