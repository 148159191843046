import React, { useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Date from '../../../components/Date';
import Invoice from '../../../components/Invoice';

import { invoiceStatuses } from '../../../constants';

const columns = [
  { id: 'invoice', label: 'Invoice' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'status', label: 'Status' },
  { id: 'pay', label: '' },
];

export default withRouter(function InvoiceList({ invoices }) {
  const unpaidInvoices = useMemo(
    () => invoices.filter(row => row.status === 'CREATED'),
    [invoices]
  );

  if (unpaidInvoices.length < 1) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Active Invoices
      </Typography>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {unpaidInvoices.map(row => {
              return (
                <TableRow tabIndex={-1} key={row.id}>
                  <TableCell>
                    <Invoice invoice={row} />
                    {row.licenseId}
                  </TableCell>
                  <TableCell>
                    <Date value={row.createdAt} />
                  </TableCell>
                  <TableCell>{invoiceStatuses[row.status]}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      target="_blank"
                      href={`/invoice/${row.id}`}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
    </>
  );
});
