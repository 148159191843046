import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useField, useForm } from 'react-final-form-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../../hooks/useStyles';
import {
  calculateInvoiceAmountAction,
  workstationLimitAction,
} from '../../../api/auth';
import { withRouter } from 'react-router-dom';
import ranger from '../../../util/range';
import PriceBlock from '../../../components/PriceBlock';
import amplitude from '../../../util/amplitude';

export default withRouter(function PaymentForm({
  licenses,
  history,
  match: { params },
}) {
  const classes = useStyles();
  const [price, setPrice] = useState(0);

  const onSubmit = useCallback(
    values => {
      workstationLimitAction(params.licenseId, values).then(response => {
        if (response.messageCode === 'LICENSE_HAS_INVOICE_STATUS_NOT_FINAL') {
          alert('License has already pending active invoice.');
        }
        else if (response.item) {
          amplitude.getInstance().logEvent('Create Workstation Limit Invoice');
          history.push(`/invoice/${response.item._id}`);
        }
      });
    },
    [history, params.licenseId]
  );

  const license = useMemo(
    () => licenses.find(license => license.id === params.licenseId),
    [licenses, params.licenseId]
  );

  const onCancel = useCallback(() => history.push('/dashboard'), [history]);

  const { form, handleSubmit } = useForm({
    onSubmit,
    mutators: {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    },
  });

  const workstationLimitField = useField('workstationLimit', form);

  const rangeArr = useMemo(() => {
    return ranger(parseInt((license && license.workstationLimit + 1) || 1), 20);
  }, [license]);

  useEffect(() => {
    if (license) {
      form.mutators.setValue('workstationLimit', license.workstationLimit + 1);
    }
  }, [license, form.mutators]);

  useEffect(() => {
    if (license && workstationLimitField.input.value) {
      calculateInvoiceAmountAction({
        licenseId: license.id,
        workstationLimit: parseInt(workstationLimitField.input.value),
        product: {
          _id: 'CITYSCAPE_PRO-CUSTOM',
          days: 0,
          price: {
            amount: '0.00',
            currency: 'USD',
          },
        },
      }).then(response => {
        if (response.item) {
          setPrice({
            pricePerWorkstation: response.item.pricePerWorkstation,
            totalAmount: response.item.totalAmount,
            subTotalAmount: response.item.subTotalAmount,
            discountAmount: response.item.discountAmount,
          });
        } else {
          setPrice(null);
        }
      });
    }
  }, [license, workstationLimitField.input.value]);

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Workstation Limit Form');
  }, [])

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {license ? (
            <span>
              Current workstation Limit: <b>{license.workstationLimit}</b>
            </span>
          ) : (
            ''
          )}{' '}
          <br />
          {license ? (
            <span>
              Expires at: <b>{license.endDate.slice(0, 10)}</b>
            </span>
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-select-currency-native"
            select
            label="Workstation Limit"
            SelectProps={{
              native: true,
            }}
            {...workstationLimitField.input}
            fullWidth
            helperText="Render nodes are unlimited. Get additional discount when you purchase multiple workstation licenses"
            variant="outlined"
          >
            {rangeArr.map(n => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </TextField>
          <PriceBlock price={price} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.margin}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Increase Workstation Limit
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.submit}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
});
