import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import queryString from 'qs';
import { useForm, useField } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright';
import useStyles from '../../hooks/useStyles';
import { signInAction } from '../../api/auth';
import { Alert } from '@material-ui/lab';
import { Redirect, withRouter } from 'react-router-dom';
import Logo from '../../components/Logo';
import amplitude from '../../util/amplitude';
import OauthBlock from '../../components/OauthBlock';
import { errors } from '../../constants';

export default withRouter(function SignIn({location: { search }}) {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const onSubmit = useCallback(async ({ email, password }) => {
    const response = await signInAction({ email, password });

    if (!response.token) {
      setError(get(response, 'errors[0]', 'Invalid Credentials'));
    } else {
      window.localStorage.setItem('token', response.token);
      amplitude.getInstance().logEvent('Login', { loginType: 'DEFAULT' });
      setSuccess(true);
    }
  }, []);

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  const emailField = useField('email', form);
  const passwordField = useField('password', form);

  useEffect(() => {
    const query = queryString.parse(search, { ignoreQueryPrefix: true });

    if (query.error && Object.keys(errors).includes(query.error)) {
      setError(errors[query.error]);
    }
  }, [search])

  useEffect(() => {
    amplitude.getInstance().logEvent('PageView - Login');
  }, [])

  if (success) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <OauthBlock />
        <br />
        <Typography variant="button">or</Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            {...emailField.input}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            {...passwordField.input}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {error ? <Alert severity="error">{error}</Alert> : ''}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/register" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
})
