import React from 'react';
import Button from '@material-ui/core/Button';
import { url } from '../config';

export default () => (
  <>
    <br />
    <br />
    <Button
      variant="contained"
      fullWidth
      color="default"
      href={url + '/connect/google'}
      startIcon={<img src="/google-icon.svg" height="20" width="20" alt="G" />}
    >
      Continue with Google
    </Button>
  </>
)