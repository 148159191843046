import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { useField, useForm } from 'react-final-form-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../../hooks/useStyles';
import {
  buyNewLicenseAction,
  calculateInvoiceAmountAction,
} from '../../../api/auth';
import { products } from '../../../constants';
import PriceBlock from '../../../components/PriceBlock';
import ReactPixel from 'react-facebook-pixel';
import amplitude from '../../../util/amplitude';

export default withRouter(function PaymentForm({ history }) {
  const classes = useStyles();
  const [price, setPrice] = useState(299.99);

  const onSubmit = useCallback(
    values => {
      buyNewLicenseAction(values).then(response => {
        ReactPixel.track('AddToCart', {
          currency: 'USD',
          value: price,
        });
        if (response.item) {
          amplitude.getInstance().logEvent('Create New License Invoice');
          history.push(`/invoice/${response.item._id}`);
        }
      });
    },
    [history, price]
  );

  const onCancel = useCallback(() => history.push('/dashboard'), [history]);

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      workstationLimit: 1,
      productId: 'CITYSCAPE_PRO-YEAR',
    },
  });

  const workstationLimitField = useField('workstationLimit', form);
  const productIdField = useField('productId', form);

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Buy License Form');
  }, [])

  useEffect(() => {
    if (workstationLimitField.input.value) {
      calculateInvoiceAmountAction({
        workstationLimit: parseInt(workstationLimitField.input.value),
        product: products[productIdField.input.value],
      }).then(response => {
        if (response.item) {
          setPrice({
            pricePerWorkstation: response.item.pricePerWorkstation,
            totalAmount: response.item.totalAmount,
            subTotalAmount: response.item.subTotalAmount,
            discountAmount: response.item.discountAmount,
          });
        } else {
          setPrice(null);
        }
      });
    }
  }, [productIdField.input.value, workstationLimitField.input.value]);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-select-currency-native"
            select
            label="Workstation Count"
            SelectProps={{
              native: true,
            }}
            {...workstationLimitField.input}
            fullWidth
            helperText="Render nodes are unlimited. Get additional discount when you purchase multiple workstation licenses"
            variant="outlined"
          >
            {[...Array(20).keys()].map(n => (
              <option key={n++} value={n}>
                {n}
              </option>
            ))}
          </TextField>
          <br /> <br />
          <FormControl component="fieldset">
            <FormLabel component="legend">License Type</FormLabel>
            <RadioGroup aria-label="productId" {...productIdField.input}>
              <FormControlLabel
                value="CITYSCAPE_PRO-MONTH"
                control={<Radio />}
                label="1 Month"
              />
              <FormControlLabel
                value="CITYSCAPE_PRO-YEAR"
                control={<Radio />}
                label="1 Year"
              />
            </RadioGroup>
          </FormControl>
          <PriceBlock price={price} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.margin}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Buy a new License
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.submit}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
});
