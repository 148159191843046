import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import amplitude from '../../util/amplitude';
import history from '../../util/history';
import { renewTokenAction } from '../../api/auth';
import { errors } from '../../constants';

export default withRouter(function Connect({ match: { params } }) {
  useEffect(() => {
    renewTokenAction(params.token).then(response => {
      if (response.token) {
        window.localStorage.setItem('token', response.token);
        amplitude.getInstance().logEvent('Login', { loginType: 'GOOGLE' });
        history.push('/dashboard');
      } else {
        window.location.href = `/login?error=${errors['google.could_not_fetch_user']}`;
      }
    });
  }, [params]);

  return (
    <Backdrop open timeout={100}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});
