import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChangeProfile from './components/ChangeProfile';
import LicenseList from './components/LicenseList';
import Downloads from './components/Downloads';
import ChangePassword from './components/ChangePassword';
import Modal from './components/Modal';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Route, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import NewLicenseBlock from './components/NewLicenseBlock';
import TrialLicenseForm from './components/TrialLicenseForm';
import BuyLicenseForm from './components/BuyLicenseForm';
import {
  authenticateAction,
  listInvoiceAction,
  listLicensesAction,
} from '../../api/auth';
import get from 'lodash/get';
import ExtendLicenseForm from './components/ExtendLicenseForm';
import WorkstationLimitForm from './components/WorkstationLimitForm';
import InvoiceList from './components/InvoiceList';
import Copyright from '../../components/Copyright';
import ReactPixel from 'react-facebook-pixel';
import amplitude from '../../util/amplitude';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  links: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default withRouter(({ location: { search } }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customer, setCustomer] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const open = Boolean(anchorEl);

  useEffect(() => {
    authenticateAction().then(({ customer }) => {
      if (customer) {
        setCustomer(customer);

        amplitude.getInstance().setUserId(customer.id);
        amplitude.getInstance().logEvent('PageView - Dashboard');
        ReactPixel.init('1192165361131843', {
          em: customer.email,
          fn: customer.profile.firstName,
          ln: customer.profile.lastName,
        });

      } else {
        window.location.href = '/login' + search;
      }
    });

    listLicensesAction().then(listLicenses =>
      setLicenses(get(listLicenses, 'items', []))
    );

    listInvoiceAction().then(list => {
      setInvoices(get(list, 'items', []));
    });
  }, [search]);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!customer) {
    return <></>
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Simpolium account
          </Typography>
          <div className={classes.links}>
            <Typography variant="button">
              <Link href="https://www.simpolium.com" color="inherit">
                Home
              </Link>
            </Typography>
            <Typography variant="button">
              <Link href="https://www.simpolium.com/tutorials/" color="inherit">
                Tutorials
              </Link>
            </Typography>
            <Typography variant="button">
              <Link
                href="https://www.simpolium.com/documentation/"
                color="inherit"
              >
                Documentation
              </Link>
            </Typography>
            <Typography variant="button">
              <Link
                target="_blank"
                href="https://forum.simpolium.com"
                color="inherit"
              >
                Forum
              </Link>
            </Typography>
            <Typography variant="button">
              <Link
                target="_blank"
                href="https://www.simpolium.com/support"
                color="inherit"
              >
                Contact
              </Link>
            </Typography>
          </div>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              style={{ marginLeft: 15 }}
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <Typography variant="body1" style={{ marginLeft: 5 }}>
                {customer ? customer.profile.firstName : ''}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem component="a" href="/dashboard/change-profile">
                Profile
              </MenuItem>
              <MenuItem component="a" href="/dashboard/change-password">
                Change Password
              </MenuItem>
              <MenuItem component="a" href="/logout">
                Log Out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <CssBaseline />
      <br />
      <Container fixed>
        <InvoiceList invoices={invoices} />
        <LicenseList licenses={licenses} />
        <br />
        <NewLicenseBlock hasLicense={licenses.length} />
        <br />
        {licenses && licenses.length > 0 ? <Downloads licenseId={licenses[0].id}/> : ''}
        <br />
        <br />
        <Copyright />
        <br />
        <br />
      </Container>
      <Route
        path={'/dashboard/change-password'}
        render={() => (
          <Modal title="Change Password">
            <ChangePassword />
          </Modal>
        )}
      />
      <Route
        path={'/dashboard/change-profile'}
        render={() => (
          <Modal title="Change Profile">
            <ChangeProfile />
          </Modal>
        )}
      />
      <Route
        path={'/dashboard/request-license'}
        render={() => (
          <Modal title="Start 30 Day Free Trial">
            <TrialLicenseForm />
          </Modal>
        )}
      />
      <Route
        path={'/dashboard/buy-license'}
        render={() => (
          <Modal title="Buy License">
            <BuyLicenseForm />
          </Modal>
        )}
      />
      <Route
        path={'/dashboard/extend-license/:licenseId'}
        render={() => (
          <Modal title="Extend License">
            <ExtendLicenseForm licenses={licenses} />
          </Modal>
        )}
      />
      <Route
        path={'/dashboard/workstation-limit/:licenseId'}
        render={() => (
          <Modal title="Increase Workstation Limit">
            <WorkstationLimitForm licenses={licenses} />
          </Modal>
        )}
      />
    </div>
  );
});
