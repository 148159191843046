import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { purposes } from '../constants';

function Purpose({ purpose }) {
  return (
    <Typography variant="button">
      <b>{purposes[purpose] || purposes['EXTEND']}</b>
    </Typography>
  );
}

Purpose.propTypes = {
  purpose: PropTypes.string.isRequired,
};

export default Purpose;
