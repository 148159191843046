import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import * as moment from 'moment';

function Date({ value }) {
  const [date, time] = moment
    .utc(value)
    .format('YYYY-MM-DD HH:mm:ss')
    .split(' ');

  return (
    <Typography
      variant="button"
      align="center"
      style={{ width: '80px', display: 'block' }}
    >
      <b>{date}</b> <br />
      <small>{time}</small>
    </Typography>
  );
}

Date.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Date;
