import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Purpose from './Purpose';

function Invoice({ invoice }) {
  return (
    <Typography style={{ width: '250px', display: 'block' }}>
      <small>
        <Purpose purpose={invoice.purpose} />
      </small>
    </Typography>
  );
}

Invoice.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default Invoice;
