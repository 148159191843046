import React, { useEffect } from 'react';
import { activateAccountAction } from '../../api/auth';
import { withRouter } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import amplitude from '../../util/amplitude';

export default withRouter(function CustomerActivate({
  match: { params },
}) {
  useEffect(() => {
    activateAccountAction(params.token).then(response => {
      if (response.statusCode !== 200) {
        window.location.href = '/forgot-password';
      } else {
        window.location.href = '/dashboard';
        window.localStorage.setItem('token', response.token);
        amplitude.getInstance().logEvent('Activate Account');
        amplitude.getInstance().logEvent('Login');
      }
    });
  }, [params]);

  return (
    <Backdrop open timeout={100}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});
