import React, { useCallback, useState } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright';
import useStyles from '../../hooks/useStyles';
import { resetPasswordAction } from '../../api/auth';
import { Alert } from '@material-ui/lab';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Logo from '../../components/Logo';
import amplitude from '../../util/amplitude';

export default withRouter(function ResetPassword({
  match: { params },
}) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(
    async ({ newPassword }) => {
      setLoading(true);
      const response = await resetPasswordAction(newPassword, params.token);

      if (response.statusCode !== 200) {
        setError(get(response, 'errors[0]', 'Invalid Fields'));
      } else {
        window.location.href = '/login';
        amplitude.getInstance().logEvent('Reset Password', { success: true });
      }
      setLoading(false);
    },
    [params]
  );

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  const passwordField = useField('newPassword', form);
  const repeatPasswordField = useField('repeatPassword', form);

  return (
    <Container component="main" maxWidth="xs">
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <CssBaseline />
      <div className={classes.paper}>
        <Logo className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              {...passwordField.input}
              label="New Password"
              type="password"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              {...repeatPasswordField.input}
              label="Repeat Password"
              type="password"
            />
            {error ? <Alert severity="error">{error}</Alert> : ''}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
});
