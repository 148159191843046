import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

function PriceBlock({ price }) {
  return (
    <Typography variant="button" display="block" gutterBottom>
      {price ? (
        <>
          <Grid item xs={12}>
            Subtotal: {price.subTotalAmount} USD
          </Grid>
          <Grid item xs={12}>
            Discount: {price.discountAmount} USD
          </Grid>
          <Grid item xs={12}>
            Total: {price.totalAmount} USD
          </Grid>
        </>
      ) : (
        ''
      )}
    </Typography>
  );
}

PriceBlock.propTypes = {
  price: PropTypes.object.isRequired,
};

export default PriceBlock;
