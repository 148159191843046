import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import useStyles from '../../../hooks/useStyles';
import { changePasswordAction } from '../../../api/auth';
import { Alert } from '@material-ui/lab';
import history from '../../../util/history';
import get from 'lodash/get';
import amplitude from '../../../util/amplitude';

export default function ChangePassword() {
  const [error, setError] = useState('');
  const classes = useStyles();

  const onSubmit = useCallback(
    async ({ currentPassword, newPassword, repeatPassword }) => {
      if (newPassword !== repeatPassword) {
        setError('Passwords do not match');
      } else {
        const response = await changePasswordAction({
          currentPassword,
          newPassword,
        });

        if (response.errors) {
          setError(get(response, 'errors[0]', 'Invalid Password'));
        } else {
          history.push('/dashboard');
        }
      }
    },
    []
  );

  const onCancel = () => {
    history.push('/dashboard');
  };

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Change Password Form');
  }, [])

  const currentPasswordField = useField('currentPassword', form);
  const passwordField = useField('newPassword', form);
  const repeatPasswordField = useField('repeatPassword', form);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {error ? <Alert severity="error">{error}</Alert> : ''}

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        type="password"
        label="Current Password"
        {...currentPasswordField.input}
        autoFocus
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        {...passwordField.input}
        label="New Password"
        type="password"
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        {...repeatPasswordField.input}
        label="Repeat Password"
        type="password"
      />
      <div className={classes.margin}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Change Password
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.submit}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
}
