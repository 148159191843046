import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import qs from 'qs';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';

export default withRouter(({ location }) => {
  const successPayment = qs.parse(location.search, { ignoreQueryPrefix: true }).successPayment !== undefined;
  const [success, setSuccess] = useState(successPayment);

  return (<Collapse in={success}>
    <Alert
      variant="filled"
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => setSuccess(false)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
      severity="success"
    >
      <AlertTitle>Thank you for your payment.</AlertTitle>
      Your transaction has been completed, and details have been emailed to you.
    </Alert>
  </Collapse>)
});
