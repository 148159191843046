import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import useInterval from '@use-it/interval';
import history from './util/history';
import { renewTokenAction } from './api/auth';
import LogOut from './routes/Dashboard/components/LogOut';
import ForgotPassword from './routes/ForgotPassword';
import ResetPassword from './routes/ResetPassword';
import SignIn from './routes/SignIn';
import Register from './routes/Register';
import Dashboard from './routes/Dashboard';
import CustomerActivate from './routes/CustomerActivate/CustomerActivate';
import Invoice from './routes/Invoice/Invoice';
import SuccessAlert from './components/SuccessAlert';
import Oauth from './routes/Oauth/Oauth';
import './App.css';


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#21c0b4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#ff9100',
      contrastText: '#fff',
    },
  },
});

ReactPixel.init('1192165361131843');
ReactPixel.pageView();

const App = () => {
  useInterval(async () => {
    if (window.localStorage.getItem('token')) {
      renewTokenAction().then(response => {
        if (!response.token) {
          return <Redirect to="/logout" />;
        }
      });
    }
  }, 100000);

  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <SuccessAlert />
        <Switch>
          <Route path="/invoice/:invoiceId">
            <Invoice />
          </Route>
          <Route path="/login">
            <SignIn />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/reset-password/:token">
            <ResetPassword />
          </Route>
          <Route path="/activate/:token">
            <CustomerActivate />
          </Route>
          <Route path="/oauth/:token">
            <Oauth />
          </Route>
          <Route path="/logout">
            <LogOut />
          </Route>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
};

export default App;
