import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';
import ReactPixel from 'react-facebook-pixel';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright';
import useStyles from '../../hooks/useStyles';
import { useField, useForm } from 'react-final-form-hooks';
import { getCountryAction, registerAction } from '../../api/auth';
import { Alert } from '@material-ui/lab';
import { countryList } from '../../config';
import Logo from '../../components/Logo';
import amplitude from '../../util/amplitude';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import OauthBlock from '../../components/OauthBlock';

export default function SignUp() {
  const [error, setError] = useState('');
  const [country, setCountry] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const classes = useStyles();

  const onSubmit = useCallback(async (values) => {
    const response = await registerAction(values);
    if (response.errors) {
      setError(get(response, 'errors[0]', 'Invalid Fields'));
    } else {
      setSuccess(true);
      ReactPixel.init('1192165361131843', {
        em: values.email,
        fn: values.firstName,
        ln: values.lastName,
        country: values.country,
      });
      ReactPixel.track('CompleteRegistration', {
        currency: 'USD',
        value: 0
      });
      amplitude.getInstance().logEvent('CompleteRegistration');
    }
  }, []);

  const { form, handleSubmit } = useForm({
    onSubmit,
    mutators: {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    },
  });

  const firstNameField = useField('firstName', form);
  const lastNameField = useField('lastName', form);
  const countryField = useField('country', form);
  const passwordField = useField('password', form);
  const emailField = useField('email', form);

  useEffect(() => {
   amplitude.getInstance().logEvent('PageView - Register');
    getCountryAction().then((response) => {
      if (response.item && response.item.length === 2) {
        form.mutators.setValue('country', response.item)
      } else {
        setCountry(true);
      }
    })
  }, [form.mutators])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo className={classes.avatar} />
        <Typography component="h1" variant="h5" >
          Sign up for your account
        </Typography>
        <OauthBlock />
        <br />
        <Typography variant="button">or</Typography>
        <br />
        <form className={classes.form} onSubmit={handleSubmit}>
          {success ? (
            <div>
              <Alert severity="success">
                Please check your email to for validation.
              </Alert>
              <Button
                href="/login"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Login
              </Button>
            </div>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  {...firstNameField.input}
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  {...lastNameField.input}
                  autoComplete="lname"
                />
              </Grid>
              {country &&
              <Grid item xs={12}><TextField
                  id="country"
                  select
                  required
                  label="Country"
                  SelectProps={{
                    native: true,
                  }}
                  {...countryField.input}
                  fullWidth
                  variant="outlined"
                >
                  <option value=""></option>
                  {countryList.map(({ name, code }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </TextField>
              </Grid>}
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  {...emailField.input}
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  {...passwordField.input}
                  label="Password"
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  InputProps={{ // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onMouseDown={toggleShowPassword}
                          onMouseUp={toggleShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {error ? <Alert severity="error">{error}</Alert> : ''}
              </Grid>
              <Grid item xs={12}>
                <small>
                By signing up, I accept to the
                <Link
                  href="https://www.simpolium.com/terms-and-conditions/"
                  target="_blank"
                >
                  {' '}
                  Terms and Conditions{' '}
                </Link>
                and
                <Link href="https://www.simpolium.com/eula/" target="_blank">
                  {' '}
                  EULA
                </Link>       {' '} and acknowledge the <Link href="https://www.simpolium.com/privacy-policy/" target="_blank">
                {' '}
                Privacy Policy
              </Link>
                </small>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Already have an account? Sign in
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}
