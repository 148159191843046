import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://55fc502432f943e3b7b84af761ed9af1@o434174.ingest.sentry.io/5390757"});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
