import React, { useCallback, useState } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Copyright from '../../components/Copyright';
import useStyles from '../../hooks/useStyles';
import { forgotPasswordAction } from '../../api/auth';
import { Alert } from '@material-ui/lab';
import Logo from '../../components/Logo';

export default function ChangePassword() {
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  const onSubmit = useCallback(async ({ email }) => {
    const response = await forgotPasswordAction({ email });

    if (response.statusCode !== 200) {
    } else {
      setSuccess(true);
    }
  }, []);

  const { form, handleSubmit } = useForm({
    onSubmit,
  });

  const emailField = useField('email', form);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Logo className={classes.avatar} />
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          {success ? (
            <Alert severity="success">
              Reset password Link have been sent to your email.
            </Alert>
          ) : (
            <div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="email"
                label="Email"
                {...emailField.input}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </div>
          )}
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
