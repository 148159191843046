const statusCode = {
  NEW: 'New',
  ACTIVATED: 'Active',
  GRANTED: 'Granted',
  DEACTIVATED: 'Deactivated',
  EXPIRED: 'Expired',
};

const statusColor = {
  NEW: 'initial',
  ACTIVATED: 'primary',
  GRANTED: 'secondary',
  DEACTIVATED: 'initial',
};

const invoiceStatuses = {
  CREATED: 'Not Paid',
  PAID_PARTIALLY: 'Partially Paid',
  COMPLETED: 'Paid',
  DECLINED: 'Declined',
};

const purposes = {
  BUY_NEW: 'Buy New License',
  CHANGE_WORKSTATION_LIMIT: 'Change Workstation Limit',
  EXTEND: 'Extend License Period',
};

const products = {
  'CITYSCAPE_PRO-MONTH': {
    _id: 'CITYSCAPE_PRO-MONTH',
    days: 30,
    price: {
      amount: '39.99',
      currency: 'USD',
    },
  },
  'CITYSCAPE_PRO-YEAR': {
    _id: 'CITYSCAPE_PRO-YEAR',
    days: 365,
    price: {
      amount: '299.99',
      currency: 'USD',
    },
  },
};

const errors = {
  'google.invalid_token': 'Token is invalid, Please try again',
  'google.invalid_state': 'Invalid State, Please try again',
  'google.could_not_fetch_user': 'Could not fetch user via google'
}

export { statusCode, statusColor, purposes, invoiceStatuses, products, errors };
