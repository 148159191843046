import { url } from '../config';

const action = async (path, method, values, token) => {
  try {
    const params = {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    if (token) {
      params.headers.Authorization = `Bearer ${token}`;
    }

    if (method !== 'GET') {
      params.body = JSON.stringify(values);
    }

    const response = await fetch(`${url}/client${path}`, params);

    return await response.json();
  } catch (err) {
    return err;
  }
};

const signInAction = async values => action('/customer/login', 'POST', values);

const changePasswordAction = async values => {
  const token = window.localStorage.getItem('token');

  return action('/customer/change-password', 'PUT', values, token);
};

const forgotPasswordAction = async values =>
  action('/customer/forgot-password', 'POST', values);

const resetPasswordAction = async (newPassword, token) =>
  action('/customer/reset-password', 'PUT', { newPassword }, token.replace(/-dot-/g, '.'));

const activateAccountAction = async token =>
  action('/customer/activate', 'PUT', {}, token.replace(/-dot-/g, '.'));

const renewTokenAction = async oldToken => {
  const token = oldToken || window.localStorage.getItem('token');

  return action('/customer/renew-token', 'GET', null, token);
};

const registerAction = async values =>
  action('/customer/register', 'POST', values);

const publicInvoiceAction = async invoiceId =>
  action(`/invoice/${invoiceId}/public`, 'GET', null);

const getCountryAction = async () =>
  action(`/country`, 'GET', null);

const changeProfileAction = async values => {
  const token = window.localStorage.getItem('token');

  return action('/customer/profile-update', 'PUT', values, token);
};

const authenticateAction = async () => {
  const token = window.localStorage.getItem('token');

  return action('/customer/authenticate', 'GET', null, token);
};

const calculateInvoiceAmountAction = ({ licenseId, ...values }) => {
  let url = '/invoice/calculate-amount';
  const token = window.localStorage.getItem('token');

  if (licenseId) {
    url += `?licenseId=${licenseId}`;
  }

  return action(url, 'POST', values, token);
};

const listLicensesAction = async () => {
  const token = window.localStorage.getItem('token');

  return action('/license/customers-licenses', 'GET', null, token);
};

const listInvoiceAction = async () => {
  const token = window.localStorage.getItem('token');

  return action('/invoice/customers-invoices', 'GET', null, token);
};

const requestTrialAction = async values => {
  const token = window.localStorage.getItem('token');

  return action('/license/cityscape-pro-trial', 'POST', values, token);
};

const buyNewLicenseAction = async values => {
  const token = window.localStorage.getItem('token');

  return action('/license/buy', 'POST', values, token);
};

const extendLicenseAction = async (licenseId, values) => {
  const token = window.localStorage.getItem('token');

  return action(`/license/${licenseId}/extend`, 'PUT', values, token);
};

const workstationLimitAction = async (licenseId, values) => {
  const token = window.localStorage.getItem('token');

  return action(
    `/license/${licenseId}/change-workstation-limit`,
    'PUT',
    values,
    token
  );
};

const logOutAction = () => {
  window.localStorage.removeItem('token');
};

const variableAction = () => {
  return action('/variable/download-file-path', 'GET', null);
};

export {
  signInAction,
  registerAction,
  renewTokenAction,
  logOutAction,
  changePasswordAction,
  forgotPasswordAction,
  resetPasswordAction,
  changeProfileAction,
  authenticateAction,
  listLicensesAction,
  requestTrialAction,
  activateAccountAction,
  variableAction,
  publicInvoiceAction,
  buyNewLicenseAction,
  extendLicenseAction,
  workstationLimitAction,
  listInvoiceAction,
  calculateInvoiceAmountAction,
  getCountryAction,
};
