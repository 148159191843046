import React, { useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { statusCode, statusColor } from '../../../constants';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import amplitude from '../../../util/amplitude';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function LicenseList({ licenses }) {
  const classes = useStyles();

  const daysRemaining = useCallback(
    date => {
      const daysLeft =  moment(date).diff(moment(), 'days');

      return daysLeft > 0 ? `${daysLeft} days left` : `Expired ${-daysLeft} days ago`;
    },
    []
  );



  if (!licenses.length) {
    return (
      <>
        <Typography variant="h5" gutterBottom>
          Licenses
        </Typography>
        <Typography variant="body1">Nothing found</Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Licenses
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Product Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>License Id (Serial Number)</TableCell>
              <TableCell>Workstation Count</TableCell>
              <TableCell>Valid Period</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {licenses.map((license) => ({
                ...license,
                status: new Date(license.endDate) > new Date() || license.status === 'NEW' ? license.status : 'EXPIRED'}))
                .sort((prev, {status}) => {
                    if (status === 'EXPIRED') { return -1 }})
                .map(
              ({
                id,
                createdAt,
                workstations,
                workstationLimit,
                products,
                detail,
                status,
                endDate,
              }) => (
                <TableRow key={id} style={status === 'EXPIRED' ? { backgroundColor: "rgb(224 224 224)" } : {}}>
                  <TableCell>
                    <Typography>
                    Cityscape Pro
                    </Typography>
                    {detail === 'AUTODESK' ? <Typography variant="caption">Autodesk Subscriptions are only managed in Autodesk App Store</Typography>: '' }
                  </TableCell>
                  <TableCell>
                    <Typography variant="button" color={statusColor[status]}>
                      <b>{statusCode[status]}</b>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {id}{' '}
                    <CopyToClipboard text={id}>
                      <span>
                        <Chip
                          variant="outlined"
                          size="small"
                          label="copy"
                          onClick={() => {}}
                          color="primary"
                        />
                      </span>
                    </CopyToClipboard>
                  </TableCell>
                  <TableCell>
                    {`${workstations.length} out of ${workstationLimit}`}{' '}
                    {(products.length === 1 && products[0].product.id === 'CITYSCAPE_PRO-TRIAL') ||
                      detail === 'AUTODESK' ? '' : <Button
                      href={`/dashboard/workstation-limit/${id}`}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      Increase
                    </Button>
                    }
                  </TableCell>
                  <TableCell>
                    {status === 'NEW' ? 'Not used Yet' :
                    <>
                      {createdAt.substring(0, 10)} - {endDate.substring(0, 10)}{' '}
                    <br /> <small>({daysRemaining(endDate)})</small>
                    </>}
                  </TableCell>
                  <TableCell>
                    {detail === 'AUTODESK' ? <Button
                        href="https://apps.autodesk.com/3DSMAX/en/Detail/Index?id=624768016273161068"
                        color="primary"
                        onClick={() =>  amplitude.getInstance().logEvent('Autodesk Change Button')}
                        variant="contained"
                      >
                        Change
                      </Button> :
                      <Button
                        href={`/dashboard/extend-license/${id}`}
                        color="secondary"
                        variant="contained"
                      >
                        Extend
                      </Button>
                    }
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}


