import React, { useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { useField, useForm } from 'react-final-form-hooks';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import useStyles from '../../../hooks/useStyles';
import { requestTrialAction } from '../../../api/auth';
import { withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import amplitude from '../../../util/amplitude';

export default withRouter(function PaymentForm({ history }) {
  const classes = useStyles();
  const onSubmit = useCallback(
    values => {
      requestTrialAction({ workstationLimit: parseInt(values.workstationLimit) }).then(response => window.location.href = '/dashboard');
      ReactPixel.track('StartTrial', {
        currency: 'USD',
        value: 0
      });
      amplitude.getInstance().logEvent('Start Trial');
    },
    []
  );

  const onCancel = useCallback(() => history.push('/dashboard'), [history]);

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      workstationLimit: 1,
    }
  });

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Trial Form');
  }, [])

  const workstationLimitField = useField('workstationLimit', form);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-select-currency-native"
            select
            label="Workstation Count"
            SelectProps={{
              native: true,
            }}
            {...workstationLimitField.input}
            fullWidth
            variant="outlined"
          >
            {[1, 2, 3, 4, 5].map(n => (
              <option key={n} value={n}>
                {n}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.margin}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Start 30-day Trial License
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.submit}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
});
