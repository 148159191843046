import amplitude from 'amplitude-js';
import getCookie from './getCookie';

const cookieDeviceId = getCookie('amplitude_device_id');

const config = {
  saveEvents: true,
  includeUtm: true,
  includeReferrer: true
}

if (cookieDeviceId) {
  config.deviceId = cookieDeviceId;
}

amplitude.getInstance().init("08fd5b092d7b861859ff3d2ea0961eaf", null, config);

export default amplitude;