import React from 'react';

import Button from '@material-ui/core/Button';

export default function NewLicenseBlock({ hasLicense }) {
  return (
    <div>
      <div>
        {hasLicense ? (
          ''
        ) : (
          <Button
            href="/dashboard/request-license"
            variant="contained"
            color="secondary"
          >
            Start Free Trial
          </Button>
        )}

        <Button
          href="/dashboard/buy-license"
          variant="contained"
          color="primary"
          style={{ marginLeft: 20 }}
        >
          Buy a new License
        </Button>
      </div>
    </div>
  );
}
