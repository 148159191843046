import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import classes from '../../../hooks/useStyles';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Amount from './Amount';
import Purpose from './Purpose';
import Typography from '@material-ui/core/Typography';
import { invoiceStatuses } from '../../../constants';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import ReactPixel from 'react-facebook-pixel';
import { authenticateAction } from '../../../api/auth';
import amplitude from '../../../util/amplitude';
import { countryList } from '../../../config';

function InvoiceBlock({ invoice }) {

  useEffect(() => {
    if (window.localStorage.getItem('token')) {
      authenticateAction().then(({ customer }) => {
        if (customer) {
          ReactPixel.init('1192165361131843', {
            em: customer.email,
            fn: customer.profile.firstName,
            ln: customer.profile.lastName,
            country: customer.profile.address.country,
          });

          ReactPixel.track('ViewContent');
        }
      });
    }

    amplitude.getInstance().logEvent('View Invoice');
  }, []);

  const handleCheckout = () => {
    amplitude.getInstance().logEvent('Initiate Checkout', {
      value: invoice.price.totalAmount,
      currency: 'USD',
    });

    ReactPixel.track('InitiateCheckout', {
      value: invoice.price.totalAmount,
      currency: 'USD',
    });
  }

  return (
    <div className={classes.root}>
      <Container>
        <Toolbar>
          <Link href="https://www.simpolium.com">
            <img alt="logo" src="/logo.png" height={30} />
          </Link>
        </Toolbar>
        <Paper style={{ padding: 20 }} elevation={0} className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">Invoice details</Typography>
              <Typography variant="subtitle1">
                <small>ID: {invoice._id}</small>
              </Typography>
              <Typography variant="subtitle1">
                <small>
                  Date of issue: {invoice.createdAt.substring(0, 10)}
                </small>
              </Typography>
               <Typography variant="subtitle1">
                <small>
                  Invoice to: <b>{`${invoice.profile.firstName} ${invoice.profile.lastName}, ${invoice.profile.companyName ? invoice.profile.companyName + ', ' : ''}
			${countryList.find(({name, code}) => code === invoice.profile.address.country)?.name}`}</b>
                </small>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography align="right" variant="subtitle2" >
                  <b>Simpolium LLC</b> <br/>
              ID 404593089<br/>
              S. Chikovani Street, N18 0171<br/>
              Tbilisi, Georgia,<br/>
              Phone: +995 599-046-033<br/>
              Email: support@simpolium.com <br/><br/>
                </Typography>
              <Typography align="right" variant="h5">
                {invoiceStatuses[invoice.status]}
              </Typography>
            </Grid>
            <br />
            <Grid item xs={12}>
              <Paper elevation={0} className={classes.paper}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Payment Type</TableCell>
                        {invoice.purpose !== 'CHANGE_WORKSTATION_LIMIT' ? (
                          <TableCell>
                            {' '}
                            {invoice.purpose === 'BUY_NEW'
                              ? 'Valid for'
                              : 'Extended for'}
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell>Workstation Limit</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Purpose purpose={invoice.purpose} />
                        </TableCell>
                        {invoice.purpose !== 'CHANGE_WORKSTATION_LIMIT' ? (
                          <TableCell>
                            {invoice.product.product.days} days
                          </TableCell>
                        ) : (
                          <TableCell />
                        )}
                        <TableCell>
                          {invoice.product.workstationLimit}
                        </TableCell>
                        <TableCell>
                          <Amount
                            amount={{
                              amount: invoice.price.subTotalAmount,
                              currency: invoice.price.currency,
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }}>
                          <br />
                        </TableCell>
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                        <TableCell>Subtotal:</TableCell>
                        <TableCell>
                          {invoice.price.subTotalAmount}{' '}
                          {invoice.price.currency}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                        <TableCell>Discount:</TableCell>
                        <TableCell>
                          {invoice.price.discountAmount}{' '}
                          {invoice.price.currency}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }}>
                          <b>Total:</b>
                        </TableCell>
                        <TableCell id="total-amount" style={{ border: 'none' }}>
                          <b>
                            {invoice.price.totalAmount} {invoice.price.currency}
                          </b>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ border: 'none' }}>
                          <br />
                          <div className={classes.margin}>
                            <Button
                              variant="contained"
                              className="noPrint"
                              href="/dashboard"
                              color="default"
                            >
                              Go To Dashboard
                            </Button>
                            <Button
                              variant="outlined"
                              className="noPrint"
                              style={{marginLeft: 10}}
                              onClick={window.print}
                              color="default"
                            >
                              Print
                            </Button>
                          </div>
                        </TableCell>
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }} />
                        <TableCell style={{ border: 'none' }}>
                          {!['DECLINED', 'COMPLETED'].includes(
                            invoice.status
                          ) ? (
                            <>
                              <br />
                              <div className={classes.margin}>
                                <Button
                                  href={`https://api.simpolium.com/client/invoice/${invoice._id}/pay`}
                                  variant="contained"
                                  className="noPrint"
                                  id="gc-checkout"
                                  onClick={handleCheckout}
                                  color="primary"
                                >
                                  Pay with debit card
                                </Button>

                                <Button
                                  href={`https://api.simpolium.com/client/invoice/${invoice._id}/pay?provider=PAYPAL`}
                                  variant="contained"
                                  className="noPrint"
                                  color="secondary"
                                  id="paypal-checkout"
                                  onClick={handleCheckout}
                                  style={{ marginLeft: 20 }}
                                >
                                  <img
                                    alt="PayPal"
                                    src="/resources/images/paypal.svg"
                                    height={24}
                                  />
                                </Button>
                              </div>
                            </>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </div>
  );
}

InvoiceBlock.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default InvoiceBlock;
