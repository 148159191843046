import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { publicInvoiceAction } from '../../api/auth';
import InvoiceBlock from './components/InvoiceBlock';

export default withRouter(({ match: { params } }) => {
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    publicInvoiceAction(params.invoiceId).then(response => {
      if (response.statusCode === 200) {
        setInvoice(response.item);
      }
    });
  }, [params]);

  return invoice ? <InvoiceBlock invoice={invoice} /> : <b> Loading... </b>;
});
