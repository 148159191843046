import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useField, useForm } from 'react-final-form-hooks';
import Button from '@material-ui/core/Button';
import {
  calculateInvoiceAmountAction,
  extendLicenseAction,
} from '../../../api/auth';
import { withRouter } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import useStyles from '../../../hooks/useStyles';
import { products } from '../../../constants';
import PriceBlock from '../../../components/PriceBlock';
import amplitude from '../../../util/amplitude';

export default withRouter(function PaymentForm({
  licenses,
  history,
  match: { params },
}) {
  const classes = useStyles();
  const [price, setPrice] = useState(0);

  const onSubmit = useCallback(
    values => {
      extendLicenseAction(params.licenseId, values).then(response => {
        if (response.messageCode === 'LICENSE_HAS_INVOICE_STATUS_NOT_FINAL') {
          alert('License has already pending active invoice.');
        }
        else if (response.item) {
          amplitude.getInstance().logEvent('Create Extend License Invoice');
          history.push(`/invoice/${response.item._id}`);
        }
      });
    },
    [history, params]
  );

  const license = useMemo(
    () => licenses.find(license => license.id === params.licenseId),
    [licenses, params.licenseId]
  );

  const onCancel = useCallback(() => history.push('/dashboard'), [history]);

  const { form, handleSubmit } = useForm({
    onSubmit,
    initialValues: {
      productId: 'CITYSCAPE_PRO-YEAR',
    },
    mutators: {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    },
  });

  const productIdField = useField('productId', form);

  useEffect(() => {
    if (license && productIdField.input.value) {
      calculateInvoiceAmountAction({
        licenseId: license.id,
        workstationLimit: license.workstationLimit,
        product: products[productIdField.input.value],
      }).then(response => {
        if (response.item) {
          setPrice({
            pricePerWorkstation: response.item.pricePerWorkstation,
            totalAmount: response.item.totalAmount,
            subTotalAmount: response.item.subTotalAmount,
            discountAmount: response.item.discountAmount,
          });
        } else {
          setPrice(null);
        }
      });
    }
  }, [license, productIdField.input.value]);

  useEffect(() => {
    amplitude.getInstance().logEvent('Open Extend License Form');
  }, [])

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">License Type</FormLabel>
            <RadioGroup aria-label="productId" {...productIdField.input}>
              <FormControlLabel
                value="CITYSCAPE_PRO-MONTH"
                control={<Radio />}
                label="1 Month"
              />
              <FormControlLabel
                value="CITYSCAPE_PRO-YEAR"
                control={<Radio />}
                label="1 Year"
              />
            </RadioGroup>
          </FormControl>
          <PriceBlock price={price} />
        </Grid>

        <Grid item xs={12}>
          <div className={classes.margin}>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.submit}
            >
              Extend License
            </Button>
            <Button
              variant="contained"
              color="default"
              className={classes.submit}
              onClick={onCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
  );
});
